<template>
  <div>
    <span
      :class="[
        componentClasses,
        getColorType,
        getSize,
        { border: isSecondaryVariant || isSplitVariant },
      ]"
    >
      <slot>
        {{ text }}
      </slot>
    </span>
    <template v-if="isSplitVariant && splitCount !== null">
      <span
        class="border-t border-r border-b"
        :class="[componentClasses, getColorType, getSize]"
      >
        {{ splitCount }}
      </span>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "#imports";

const VARIANTS = {
  primary: {
    COLOR: {
      primary: "bg-gradient-brand-base",
      secondary: "bg-gradient-brand-magenta",
      white: "bg-white text-primary-base",
      magenta: "bg-secondary-magenta text-white",
      blue: "bg-primary-base text-white",
      blueGradient: "bg-gradient-brand-blue text-white",
      csfd: "bg-gradient-csfd text-white",
      gold: "bg-gradient-gold text-white",
      deluxe: "bg-gradient-deluxe text-white",
      club: "bg-gradient-cinestar-club text-white",
      ladies: "bg-gradient-brand-magenta text-white",
      atmos: "bg-gradient-brand-purple text-white",
      boutique: "bg-gradient-boutique text-white",
    },
  },
  secondary: {
    COLOR: {
      white: "text-white border-white",
      magenta: "text-white border-secondary-magenta-lighter",
      blue: "text-white border-primary-lighter",
      transparent: "text-white border-transparent",
    },
  },
  split: {
    white: "text-white border-white",
  },
  SIZE: {
    default: "py-[2px] px-2 3xl:px-3",
    small: "px-1",
  },
};

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: String,
      default: "default",
    },
    text: {
      type: String,
      default: null,
    },
    splitCount: {
      type: Number,
      default: null,
    },
    customClasses: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const componentClasses =
      props.customClasses ??
      "inline-flex items-center uppercase text-2xs leading-4 lg:text-sm lg:leading-5 3xl:text-base 3xl:leading-6";
    const getColorType = computed(
      () => VARIANTS?.[props.variant]?.COLOR?.[props.color] ?? "",
    );
    const getSize = computed(() => VARIANTS.SIZE?.[props.size] ?? "");
    const isSecondaryVariant = computed(() => props.variant === "secondary");
    const isSplitVariant = computed(() => props.variant === "split");

    return {
      componentClasses,
      getColorType,
      getSize,
      isSecondaryVariant,
      isSplitVariant,
    };
  },
});
</script>
